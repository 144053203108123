<template>
  <div style="min-width: 1800px;" v-if="bbis">
    <div class="title" style="background: #fff;">
      <div class="left">
        <img style="width: 100px;" src="../assets/images/logo.png" alt="">
      </div>
      
      <div class="reight">
        <!-- <div class="fhbb" style="color: #1D2129;" @click="bbxz(0)">返回旧版</div> -->
        <a-popover placement="bottomRight">
          <template #content>
          <div class="dl" style="text-align: center;cursor: pointer;" @click="out">退出登录</div>
            </template>
          <div style="cursor: pointer;">
            <span style="font-size: 16px;font-weight: 600;color: #1D2129;">Hi, {{ username }}<img style="margin-left: 12px;width: 32px;height: 32px;" src="../assets/images/toux.png" alt=""></span>
          </div>
        </a-popover>
      </div>
    </div>

    <a-layout>
      <a-layout-sider v-model:collapsed="collapsed" collapsible>
        <a-menu v-model:selectedKeys="selectedKeys" mode="inline" style="padding-top: 18px;padding-bottom: 120px;height: 100%;height: calc(100vh - 50px);overflow-y: auto;overflow-x: hidden;" v-model:openKeys="openKeys">
          <template v-for="item, i in menu_info" :key="item.id">
            <a-sub-menu>
              <template #title>
                <span>
									<span v-if="item.title == '供应商管理'" style="vertical-align: middle;margin-right: 8px;" class="icon iconfont">&#xe6e2;</span>
									<span v-if="item.title == '广告主管理'" style="vertical-align: middle;margin-right: 8px;" class="icon iconfont">&#xe6e5;</span>
									<span v-if="item.title == '财务管理'" style="vertical-align: middle;margin-right: 8px;" class="icon iconfont">&#xe6e7;</span>
									<span v-if="item.title == '质量控制'" style="vertical-align: middle;margin-right: 8px;" class="icon iconfont">&#xe6e4;</span>
									<span v-if="item.title == '核减明细'" style="vertical-align: middle;margin-right: 8px;" class="icon iconfont">&#xe6e8;</span>
									<span v-if="item.title == '系统管理'" style="vertical-align: middle;margin-right: 8px;" class="icon iconfont">&#xe6e6;</span>
									<span v-if="item.title == '数据源'" style="vertical-align: middle;margin-right: 8px;" class="icon iconfont">&#xe6e9;</span>
									<span v-if="item.title == '站点域名管理'" style="vertical-align: middle;margin-right: 8px;" class="icon iconfont">&#xe6ea;</span>
                  <span v-if="item.title == '数据处理'" style="vertical-align: middle;margin-right: 8px;" class="icon iconfont">&#xe6eb;</span>
                  <span v-if="item.title == '日志'" style="vertical-align: middle;margin-right: 8px;" class="icon iconfont">&#xe6ec;</span>
                  <span>{{ item.title }}</span>
                </span>
              </template>
              <template v-for="item2, j in item.children" :key="item2.id">
                <router-link :to="item2.href" style="color: #1D2129;">
                  <a-menu-item :key="item2.id">{{ item2.title }}</a-menu-item>
                </router-link>
              </template>
            </a-sub-menu>
          </template>



          <router-link to="/advertising" style="color: #1D2129;">
            <a-menu-item><span style="vertical-align: middle;margin-right: 8px;" class="icon iconfont">&#xe6db;</span>代码生成</a-menu-item>
          </router-link>
        </a-menu>





      </a-layout-sider>
      <a-layout>
        <a-layout-content style="margin: 20px 20px 0 20px">
          <div id="myDiv" style="height: calc(100vh - 90px);overflow-y: auto;">
            <router-view></router-view>
          </div>
        </a-layout-content>
      </a-layout>
    </a-layout>
  </div>




  <div style="min-width: 1800px;" v-if="!bbis">
    <div class="title" style="background: #28a5dc;">
      <div class="left">
        <img class="img1" src="../assets/images/logo-white.351d8083.png" alt="">
        <img class="img2" src="../assets/images/logo-word.5237af76.png" alt="">
      </div>
      <div class="reight">
        <!-- <div class="fhbb" style="color: #fff" @click="bbxz(1)">前往新版</div> -->
        <span class="username" @click="click">{{ username }}</span>
        <i> <img src="" alt=""></i>
        <div class="el-card" :class="{ 'el-card2': i, 'el-card3': !i }" style="display: ;">
          <div class="el-card_header">
            <div class="bgBox"><img src="" alt=""></div>
            <div>
              <p>{{ username }}</p>
            </div>
          </div>
          <div class="but" @click="out">退出登录</div>
        </div>
      </div>
    </div>

    <a-layout>
      <a-layout-sider v-model:collapsed="collapsed" collapsible>
        <a-menu v-model:selectedKeys="selectedKeys" theme="light" mode="inline" style="padding-top: 18px;padding-bottom: 120px;height: 100%;" v-model:openKeys="openKeys">
          <template v-for="item, i in menu_info" :key="item.id">
            <a-sub-menu>
              <template #title>
                <span>
                  <span>{{ item.title }}</span>
                </span>
              </template>
              <template v-for="item2, j in item.children" :key="item2.id">
                <router-link :to="item2.href" style="color: #1D2129;">
                  <a-menu-item :key="item2.id">{{ item2.title }}</a-menu-item>
                </router-link>
              </template>
            </a-sub-menu>
          </template>


          <router-link to="/advertising" style="color: #1D2129;">
            <a-menu-item>代码生成</a-menu-item>
          </router-link>
        </a-menu>





      </a-layout-sider>
      <a-layout>
        <a-layout-content style="margin: 20px">
          <div style="height: calc(100vh - 90px);overflow-y: auto;">
            <router-view></router-view>
          </div>
        </a-layout-content>
      </a-layout>
    </a-layout>
  </div>
  
</template>
<script setup>
import { nav_power } from "../sereive/index";
import { login_out } from "../sereive/index";
import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from "vue-router"
import Cookies from 'js-cookie';
const route = useRoute()
const router = useRouter()


let id = JSON.parse(sessionStorage.getItem("token")).id
let token = JSON.parse(sessionStorage.getItem("token")).token
let username = JSON.parse(sessionStorage.getItem("token")).username
let collapsed = ref(false)
let selectedKeys = ref(['1'])
let openKeys = ref(['1'])
let i = ref(true)
let menu_info = ref([])
let bbis = ref(true)


onMounted(async () => {
  // console.log(JSON.parse(sessionStorage.getItem("bbis")));
  // bbis.value = JSON.parse(sessionStorage.getItem("bbis"))
  await nav_power({ id, token }).then((res) => {
    menu_info.value = res.data.menuInfo
    selectedKeys.value = [`${route.meta.index}`]
    openKeys.value = [`${route.meta.key}`]
  })
})


function click() {
  i.value = !i.value
}

function out() {
  login_out({ id, token }).then((res) => {
    sessionStorage.clear()
    router.push('/')
  })
}

function bbxz(i){
  if(i){
    bbis.value = true
    Cookies.set('bbis', true, { expires: 30 })
    sessionStorage.setItem("bbis", true)
  }else{
    bbis.value = false
    Cookies.set('bbis', false, { expires: 30 })
    sessionStorage.setItem("bbis", false)
  }
}

</script>
<style lang="less" scoped>
/deep/ .ant-layout-sider-trigger {
  display: none !important;
}

#components-layout-demo-side .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}

[data-theme='dark'] .site-layout .site-layout-background {
  background: #141414;
}
.ant-layout-sider-has-trigger{
    padding-bottom: 0px;
}



.title {
  width: 100%;
  height: 50px;
  position: relative;
  display: flex;
  justify-content: space-between;
  border: 1px solid #E5E6EB;
  // background: #fff;
  color: #28a5dc;
}
.fhbb{
  padding: 6px 16px;
  margin-right: 40px;
  border: 1px solid #CCCCCC;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}
.left {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.img1 {
  width: 83px;
  height: 38px;
  margin-right: 20px;
}

.img2 {
  height: 22px;
  width: 202px;
}

.reight {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #fff;
  font-size: 14px;
  padding: 0 20px;
}

.colLine {
  width: .5px;
  height: 16px;
  background: #79c3e4;
  margin: 5px;
}

.icon-bell {
  cursor: pointer;
  padding: 12px;
  position: relative;
}

.username {
  padding: 15px 3px 15px 12px;
  cursor: pointer;
}

.el-card {
  transition: opacity 3s;
  opacity: 0;
  position: absolute;
  min-width: 220px;
  right: 2px;
  top: 108%;
  z-index: 100;
  box-sizing: 0 2px 12px 0 rgb(0 0 0 / 10%);
  border-radius: 4px;
  border: 1px solid #ebeef5;
  background-color: #fff;
  overflow: hidden;
  color: #262626;
}

.el-card2 {
  display: none;
}

.el-card3 {
  opacity: 1;
}
.dl{
	padding: 6px 32px;
	text-align: center;
}
.dl:hover{
	background-color: #f5f7f9;
}

.el-card_header {
  padding: 18px 20px;
  border-bottom: 1px solid #ebeef5;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center
}

.el-card_header div p {
  font-size: 16px;
  margin-bottom: 3px;
}

.bgBox {
  width: 50px;
  height: 50px;
  border: .5px solid #ccc;
  border-radius: 50%;
  margin-right: 18px;
}

.but {
  height: 40px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
}

.but:hover {
  background-color: rgb(245, 247, 249);
}
/deep/ .ant-menu-inline .ant-menu-item-selected::after{
  opacity: 0;
}

</style>