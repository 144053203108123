import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import * as echarts from 'echarts'
import './assets/font_4473603_sh782txnoy9/iconfont.css';
// import moment from 'moment'
// import locale from 'ant-design-vue/lib/date-picker/locale/en_US'
// import 'moment/locale/zh-cn';
// moment.locale('zh-cn')

const app = createApp(App)
app.echarts = echarts
app.use(Antd).use(store).use(router).provide('axios',axios).mount('#app')
